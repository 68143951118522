@mixin shadow-sharp($color) {
  box-shadow: 0 1px 1px rgba($color, 0.25),
              0 2px 2px rgba($color, 0.20),
              0 4px 4px rgba($color, 0.15),
              0 8px 8px rgba($color, 0.10),
              0 16px 16px rgba($color, 0.05);
}

@mixin shadow-dreamy($color) {
  box-shadow: 0 1px 2px rgba($color, 0.07),
              0 2px 4px rgba($color, 0.07),
              0 4px 8px rgba($color, 0.07),
              0 8px 16px rgba($color, 0.07),
              0 16px 32px rgba($color, 0.07),
              0 32px 64px rgba($color, 0.07);
}

@mixin shadow-diffuse($color) {
  box-shadow: 0 1px 1px rgba($color, 0.08),
              0 2px 2px rgba($color, 0.12),
              0 4px 4px rgba($color, 0.16),
              0 8px 8px rgba($color, 0.20);
}

@mixin shadow-smooth($color) {
  box-shadow: 0 2.8px 2.2px rgba($color, 0.02),
              0 6.7px 5.3px rgba($color, 0.028),
              0 12.5px 10px rgba($color, 0.035),
              0 22.3px 17.9px rgba($color, 0.042),
              0 41.8px 33.4px rgba($color, 0.05),
              0 100px 80px rgba($color, 0.07);
}

// Alert
@mixin alert-outline($border-color, $color) {
  border-color: $border-color;
  color: $color;
}

// Marker
@mixin marker-ribbon-variant($bgcolor, $color) {
  background-color: $bgcolor;
  color: $color;
  border-width: 0;

  &::before { bottom: -10px; }

  &.top-left::before { border-right-color: rgba(darken($bgcolor, 20%), .5); }
  &.top-right::before { border-left-color: rgba(darken($bgcolor, 20%), .5); }
}

// Pagination
@mixin pagination-variant($color) {
  .page-item.active {
    .page-link {
      background-color: $color;
      border-color: $color;
      color: #fff;
    }
  }

  &:not(.pagination-filled) .page-link { color: $color; }
}

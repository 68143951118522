
.bootstrap-tagsinput {
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;
  padding-top: 5px;
  padding-left: 2.5px;
  padding-right: 2.5px;
  padding-bottom: 0;
  border-color: $input-border-color;
  @include border-radius();

  .tag {
    display: inline-flex;
    align-items: center;
    background-color: $primary;
    color: #fff;
    padding: 2px 7px;
    margin: 0 2.5px 5px;
    font-size: $font-size-sm;
    @include border-radius(3px);

    [data-role="remove"] {
      display: inline-block;
      height: 16px;
      width: 10px;
      position: relative;
      opacity: .5;

      &::after {
        content: '\EB99';
        font-family: 'remixicon';
        font-size: 14px;
        position: absolute;
        top: calc(50% + .5px);
        line-height: 0;
        text-indent: -3px;
      }

      &:hover,
      &:focus {
        opacity: 1;
        box-shadow: none;
      }
    }
  }

  > input {
    min-height: auto;
    font-size: $font-size-sm;
  }

  .tt-menu {
    margin-top: 7px;
    margin-left: -10px;
    background-color: #fff;
    min-width: 150px;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid $gray-400;
    @include shadow-sharp($gray-300);
  }

  .tt-suggestion {
    font-size: $font-size-sm;
    padding: 4px 8px;
    border-radius: 3px;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $gray-200;
    }
  }
}
